<template>
  <custom-button
    class="flex items-center px-4 h-[38px]"
    size="none"
    color="warning"
    pill
    :href="link"
    target="_blank"
    is-link
  >
    <nuxt-icon
      name="common/calendar-check"
      class="stroke-0 text-[18px]"
    />
    <span
      class="hidden xs:inline ml-2 text-xs md:text-sm font-semibold"
    >{{ $t('flat.button.book.label') }}</span>
  </custom-button>
</template>

<script setup lang="ts">
import { Flat } from '~/common/types/flat/Flat'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  mobilePhone: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()

const textForSend = t('flat.button.bookFlat.messageForWhatsApp', {
  flatNumber: props.flat.flatNumber || t('flat.button.bookViewing.messageDefaultField'),
  buildingName: props.flat.building?.name || t('flat.button.bookViewing.messageDefaultField'),
  houseName: props.flat.houseName || t('flat.button.bookViewing.messageDefaultField'),
  price: props.flat.price?.amount ? `${props.flat.price.amount} ${t(`currency.options.${props.flat.price.currency}`)}` : t('flat.button.bookViewing.messageDefaultField'),
  totalArea: props.flat.totalArea || t('flat.button.bookViewing.messageDefaultField'),
  layoutName: props.flat.layout?.name || t('flat.button.bookViewing.messageDefaultField'),
  decorationType: props.flat.decoration?.decorationType ? `${t(`flat.decorationType.options.${props.flat.decoration.decorationType}.full`)}` : t('flat.button.bookViewing.messageDefaultField'),
})

const link = `https://wa.me/${props.mobilePhone}?text=${textForSend}`
</script>
